<template>
  <b-card>
    <b-row>
      <b-col sm="10">
        <h5>Dispositivos Não Utilizados</h5>
      </b-col>
      <b-col class="text-right">
        <b-dropdown
          id="dropdown-1"
          right
          variant="flat"
          class="dropdown-icon-wrapper"
        >
          <template #button-content>
            <feather-icon
              icon="AlignJustifyIcon"
              size="16"
              class="align-middle"
            />
          </template>
          <b-dropdown-item @click="onExport('xls')">
            <feather-icon
              icon="PrinterIcon"
              size="18"
            />Dowload XLS
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <Grid
      :fields="fields"
      :items="items"
      :show-border="false"
      :show-search="false"
      :show-loading="isLoading"
      @onChangePage="getData"
    >
      <template #search>

        <b-row>
          <b-col md="4">
            <i2-form-vue-select
              v-model="period"
              label=""
              :options="periodList"
              placeholder="Dias sem uso"
              name="periodo"
            />
          </b-col>
          <b-col sm="4">
            <i2-form-input
              v-model="search.serial"
              label=""
              placeholder="Seriais"
            />
          </b-col>
          <b-col sm="3">
            <organization-select v-model="search.organizations" :multiple="true" :show-label="false" />
          </b-col>
          <b-col sm="1">
            <i2-icon-button
              icon="SearchIcon"
              variant="outline-primary"
              class="mr-1"
              @click="getData"
            />
          </b-col>
        </b-row>
      </template>
      <template #cell(serial)="data">
        <u><span style="font-size: 10pt" @click="onShowDeviceDetails(data.item)">{{ data.item.serial }}</span></u>
      </template>
      <template #cell(unity)="data">
        {{ data.item.organization.path }}
      </template>
      <template #cell(url_location)="data">
        <div class="w-100">
          <a v-if="data.item.longitude" :href="data.item.url_location" target="_blank">
            {{ data.item.url_location }}
          </a>
          <b-badge
            v-if="!data.item.longitude"
            pill
            variant="danger"
          >
            Sem Localização
          </b-badge>

        </div>
      </template>
      <template #cell(last_session)="data">
        {{ data.item.last_session | moment('DD/MM/YYYY') }}
      </template>
    </Grid>
    <device-detail v-model="currentDevice" :show="showDevideDetails" @onClose="onCloseDeviceDetails" />
  </b-card>
</template>

<script>
import moment from 'moment-timezone'
import Periodlist from '@/variables/Periodlist'
import DeviceDetailMixin from '@/mixins/DeviceDetailMixin'
import DeviceDetail from '@/components/app/Device/DeviceDetail'
import OrganizationSelect from '@/components/app/Organization/OrganizationSelect'

export default {
  name: 'DevicesUnusedTable',

  components: {
    DeviceDetail,
    OrganizationSelect,
  },
  mixins: [DeviceDetailMixin],
  data() {
    return {
      isLoading: false,
      search: {
        period: '30',
        query_filter: 'unused',
        organizations: [],
      },
      period: '',
      periodList: Periodlist,
      fields: [
        {
          key: 'serial',
          label: 'Serial',
          class: 'w-2',
        },
        {
          key: 'last_user',
          label: 'Último usuário',
          class: 'w-2',
          sortable: true,
        },
        {
          key: 'last_session',
          label: 'Último acesso',
          class: 'w-2',
          sortable: true,
        },
        {
          key: 'unused_days',
          label: 'Dias',
          class: 'w-2',
          sortable: true,
        },
        {
          key: 'url_location',
          label: 'Última Localização',
          class: 'w-2',
          tdClass: 'truncate-text',
        },
        {
          key: 'city',
          label: 'Cidade',
          class: 'w-2',
          sortable: true,
        },
      ],
      items: { data: [] },
    }
  },
  watch: {
    period(value) {
      this.search.period = value
      this.getData()
    },
  },

  mounted() {
    this.period = Periodlist.find(item => item.value === 30)
    this.getData()
  },
  methods: {
    getData(page) {
      this.$http.showLoader = false
      this.isLoading = true
      this.$search.get(
        'devices',
        page,
        this.search,
        null,
      ).then(response => {
        this.$http.showLoader = true
        this.isLoading = false
        this.items = response
      })
    },

    onSelect(data) {
      this.search.start_date = moment(data.startDate).format('YYYY-MM-DD')
      this.search.end_date = moment(data.endDate).format('YYYY-MM-DD')
      this.getData()
    },

    async onExport(format) {

      const response = await this.$search.get(
        'devices/export?report=unused',
        null,
        this.search,
        null,
      )
      if (response.url !== '') {
        window.open(response.url, '_blank')
        return
      }
      this.$notify.success(response.message)
    },
  },

}
</script>

<style scoped>

</style>
