const PeriodList = [
  {
    value: 15,
    text: '15 dias',
  },
  {
    value: 30,
    text: '30 dias',
  },
  {
    value: 45,
    text: '45 dias',
  },
  {
    value: 60,
    text: '60 dias',
  },
  {
    value: 90,
    text: '90 dias',
  },
  {
    value: 365,
    text: '1 Ano',
  },
]
export default PeriodList
