<template>
  <b-container>
    <b-card-header>
      <b-card-title>
        <h1>
          Listagens
        </h1>
      </b-card-title>
    </b-card-header>
    <b-row class="match-height">
      <b-col
        sm="12"
        md="12"
      >
        <provisioned-devices-table />
      </b-col>

      <b-col
        sm="12"
        md="12"
      >
        <unprovisioned-devices-table />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        sm="12"
        md="12"
      >
        <devices-by-unity-table />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        sm="12"
        md="12"
      >
        <devices-location />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        sm="12"
        md="12"
      >
        <devices-unused-table />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ProvisionedDevicesTable from '@/views/tables/ProvisionedDevicesTable'
import UnprovisionedDevicesTable from '@/views/tables/UnProvisionedDevicesTable'
import DevicesByUnityTable from '@/views/tables/DevicesByUnityTable'
import DevicesLocation from '@/views/tables/DevicesLocationTable'
import DevicesUnusedTable from '@/views/tables/DevicesUnusedTable'

export default {
  name: 'Tables',
  components: {
    DevicesUnusedTable,
    DevicesLocation,
    DevicesByUnityTable,
    ProvisionedDevicesTable,
    UnprovisionedDevicesTable,
  },
}
</script>

<style scoped>

</style>
